<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <inbox />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ViewSkeleton from '@/components/utils/view-skeleton';
import Inbox from '@/components/inbox/inbox-all';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ViewSkeleton,
    Inbox,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('home.sidebarMenu.inbox'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  async mounted() {
    await this.$store.dispatch('v2/myinbox/getAllThreads');
  },
  async fetch({ store, params, route }) {
    console.log('params', params);
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    await store.commit('v2/myinbox/RESTORE_INITIAL_STATE');
  },
};
</script>
